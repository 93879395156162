.dropdown-list {
  position: absolute;
  background-color: white;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-top: 20px;
  z-index: 1000;
  border-radius: 10px;
  left: 0;
}

.dropdown-item {
  padding: 10px 15px; /* Add padding for spacing */
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.dropdown-item:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.dropdown-item:hover {
  background-color: #e0f7fa; /* Light blue hover effect */
}

.dropdown-list::-webkit-scrollbar {
  width: 6px;
}

.dropdown-list::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Customize scrollbar color */
  border-radius: 10px;
}

.scrollable-select::-webkit-scrollbar {
  width: 8px;
}

.scrollable-select::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-select::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-select::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite;
}

@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.item-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; /* Space between items */
  margin-bottom: 20px; /* Space between rows */
}

